export const environment = {
  production: false,
  firebase: {
      apiKey: "AIzaSyCTip4k0oDycXmwRQ_9u5fx_wdVkX9Bkbg",
      authDomain: "eo4geo-uji.firebaseapp.com",
      databaseURL: "https://eo4geo-uji.firebaseio.com",
      projectId: "eo4geo-uji",
      storageBucket: "eo4geo-uji.appspot.com",
      messagingSenderId: "697289160062",
      appId: "1:697289160062:web:b80dc4f99640f8987a2f2f",
      measurementId: "G-2P3CDENTYV"
  },
  URL_ARRAY: [
    'https://eo4geo-uji-backup.firebaseio.com/', 
    'https://eo4geo-uji-backup2-default-rtdb.europe-west1.firebasedatabase.app/', 
    'https://eo4geo-uji-backup3-default-rtdb.firebaseio.com/'
  ]
};